import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from "react-router-dom";
import {Provider} from 'react-redux';
import {createRoot} from 'react-dom/client';


import jwt_decode from 'jwt-decode'
import Cookies from 'js-cookie';

// core styles
import "./scss/volt.scss";
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.js'

import HomePage from "./pages/HomePage";
import {initData} from './reducers/recentActivity'
import {fetchDashboardConfig} from './reducers/dashboard'

import store from './store'
import * as actions from "./actions/"
import ErrorBoundary from './pages/ErrorBoundary';
import { TeacherEmail } from './api/teacher';

require('dotenv').config()
if (Cookies.get('_sw'))
  localStorage.setItem('teacher', jwt_decode(Cookies.get('_sw')).uid);
else {
  const redirect_url = 'https://next.storyworld.io/login';
  if (process.env.NODE_ENV === 'development') {
    if (process.env.REACT_APP_TEST_EMAIL !== "")
      localStorage.setItem('teacher', process.env.REACT_APP_TEST_EMAIL);
    else
      window.location.href = redirect_url;
  }
  else {
    window.location.href = window.location.href = redirect_url;;
  }
}

store.dispatch(fetchDashboardConfig).then(()=> {
  store.dispatch(initData)
})
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
root.render(
  <HashRouter>
    <ErrorBoundary>
      {/* <ScrollToTop /> */}
      <Provider store={store}>
      <HomePage /></Provider>
    </ErrorBoundary>
  </HashRouter>
);

