import {client} from '../api/client'
import * as actions from "../actions/actiontypes"
import store from '../store'

const initialState = {
    data: {
        assigned_books: {assignments: [{books: [],student: {}}], book_list: [], all_book_list: []},
        avg_booklevel_graph: [],
        avg_score: [{avg_book_level: 0, comprehension_avg: 0, overall: 0, points: 0, speaking_avg: 0, student_name: "", writing_avg: 0}],
        classes: [],
        comprehension_graph: [],
        offset: 0,
        recent_activity: [{book_name: "", comprehension: 0, date: "", speaking: 0, student_name: "", total: 0, writing: 0}],
        rows: 0,
        no_of_pages: 0
    },
    modal_overview: {comprehension: {}, activities: [], total_time_spent: {}, speaking_avg: {}, writing_avg: {}},
    modal_book: {},
    assigned_book: {
        book_meta: {}, opened: [], not_opened: []
    } , textfile:"default text", recount: 'Not submitted', inference: 'Not submitted',opinion: 'Not submitted', allow_resubmit: '', overall: '',
    isLoading: true,
    req_data: {}
}

const recentActivityReducer = (state = initialState, action)=> {
    switch(action.type) {
        case actions.RA_DEFAULT_LOAD:
        state.data = action.payload
        return state
        case actions.SET_REQ_DATA:
            return {...state, req_data: action.payload}
        case actions.RA_READ_TEXT_RESPONSE:
            state[action.key] = action.payload
            return {...state}
        case actions.RA_MODAL_BOOK:
            return {...state,modal_book:action.payload};
        case actions.RA_MODAL_OVERVIEW:
            return {...state,modal_overview:action.payload};
        case actions.ALLOW_RESUBMISSION:
            return {...state, allow_resubmit: action.payload}
        case actions.SUBMIT_OVERALL:
            return {...state, overall: action.payload}
        case actions.GET_ASSIGNED_BOOK:
            return {...state, assigned_book: action.payload}
        case actions.SET_RECENT_DATA:
            let copyState = state
            copyState.data.recent_activity = action.payload
            return {...copyState}
        case actions.SET_LOADING:
            return {...state, isLoading: action.payload}
        default:
            return state;
    }
}
// Thunk function
export async function initData(dispatch, state) {
    const data = store.getState().recent.req_data
    if(data?.class_name){
        dispatch(dataLoading(true))
        client.post(process.env.REACT_APP_API_BASE_URL+'/api/load/dashboard/data',data).then(response=>{
            dispatch({ type: actions.RA_DEFAULT_LOAD, payload: response.data })
            // store.dispatch(fetchLibrary)
            dispatch(dataLoading(false))
        },error=>{
            dispatch(dataLoading(false))
            //handle exception here
        })
    } else{
        dispatch(dataLoading(false))
    }
}

export const dataLoading = (isLoading) => {
    return {
        type: actions.SET_LOADING,
        payload: isLoading
    };
};

export default recentActivityReducer;
