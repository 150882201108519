import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
export default () => {
    const libraryURL = process.env.REACT_APP_LIBRARY_APP_URL
    return (
        <>
            <div className="phonics-heading">
                <div className="row">
                    <div className="col-2"></div>
                    <div className="col-8">
                        <center>
                            <span>STORYWORLD Integrated Phonics™</span>
                        </center>
                    </div>
                    <div className="col-2"></div>
                </div>
                <div className="row">
                    <div className="col-2"><a href={libraryURL} className="btn btn-primary" style={{background:"white", color:"#0d6efd"}}>Back to Library</a></div>
                    <div className="col-7">
                        <p>Each unit includes phonics, stories, games, activity sheets, and lesson plan.
                            <br />
                            Select the appropriate reading level and download a lesson plan to get started.
                        </p>
                    </div>

                    <div className="col-3" style={{textAlign:"right"}}>                    
                        <a href="https://storyworldpublicdocs.s3.amazonaws.com/integrated-phonics.pdf" target="_blank" className="btn btn-primary" style={{background:"white", color:"#0d6efd"}}>
                            Phonics Units (pdf) <img src={process.env.PUBLIC_URL + '/images/phonics-download.png'} className="display: displayinline" />                                                        
                        </a>                        
                    </div>
                </div>
            </div>
        </>


    )
}